export const translations = {
    'en':{
        chargingPoints:'Charging Points',
        mapView:'Map View',
        listView:'List View',
        appRating:'App Rating',
        viewFeedback:'View Feedback',
        logOut:'Log Out',
        login:'Login',
        welcomeBack:'Welcome Back',
        password: 'Password',
        termsOfUse: 'Terms of use',
        privacyPolicy: 'Privacy Policy',
        loginInfo: 'Incorrect login credentials entered.',
        createNew:'Create New',
        search:'Search',
        loading:'Loading',
        noResultsFound:'Nothing to show, please change searching phrase.',
        name:'Name',
        feedback:'Feedback',
        editPoint: 'Edit Point',
        createPoint: 'Create New Point',
        pointName:'Point Name',
        street:'Street',
        city:'City',
        zipCode:'Zip code',
        voivodeship:'Voivodeship',
        country:'Country',
        website:'Website',
        phoneNumber:'Phone Number',
        energySource:'Energy Source',
        attractions:'Attractions',
        description:'Description',
        uploadImage:'Upload Image Here',
        savingPointError:'Error while saving charging point.',
        savingPointPhotosError:'Error while saving charging point photos.',
        confirmdeletePoint:'Are you sure you want to delete this charging point?',
        badFileType:"Invalid file format",
        addTag:'Add a tag',
        discardSearchText: 'Discard search text',
        cancel:'Cancel',
        save:'Save',
        delete:'Delete',
        noPhotos:'No Photos.',
        portTypes:'Port Types',
        noAttractions:'No attractions.',
        noDescription:'No description.',
        copyText:'Value copied to clipboard.',
        share:'Share',
        deleteChargingPoint:'Delete Charging Point',
        editChargingPoint:'Edit Charging Point',
        goToWebsite:'Go to Website',
    },
    'pl':{
        chargingPoints:'Punkty Ładowania',
        mapView:'Widok Mapy',
        listView:'Widok Listy',
        appRating:'Ocena Aplikacji',
        viewFeedback:'Zobacz Opinie',
        logOut:'Wyloguj się',
        login:'Zaloguj się',
        welcomeBack:'Witaj Ponownie',
        password: 'Hasło',
        termsOfUse: 'Warunki korzystania',
        privacyPolicy: 'Polityka Prywatności',
        loginInfo: 'Wprowadzono błędne dane logowania',
        createNew:'Utwórz nowy',
        search:'Wyszukaj',
        loading:'Ładowanie',
        noResultsFound:'Brak wyników, zmień frazę wyszukiwania.',
        name:'Imię',
        feedback:'Opinia',
        editPoint: 'Edytuj punkt',
        createPoint: 'Stwórz nowy punkt',
        pointName:'Nazwa Punktu',
        street:'Ulica',
        city:'Miasto',
        zipCode:'Kod pocztowy',
        voivodeship:'Województwo',
        country:'Kraj',
        website:'Strona Internetowa',
        phoneNumber:'Numer telefonu',
        energySource:'Źródło energii',
        attractions:'Atrakacje',
        description:'Opis',
        uploadImage:'Upuść obrazek tutaj',
        savingPointError:'Błąd podczas zapisywania punktu ładowania.',
        savingPointPhotosError:'Błąd podczas zapisywania zdjęć punktu ładowania.',
        confirmdeletePoint:'Czy na pewno chcesz usunąć ten punkt ładowania?',
        badFileType:"Zły format pliku",
        addTag:'Dodaj tag',
        discardSearchText: 'Odrzuć tekst wyszukiwania',
        cancel:'Anuluj',
        save:'Zapisz',
        delete:'Usuń',
        noPhotos:'Brak zdjęć.',
        portTypes:'Typy portów',
        noAttractions:'Brak atrakcji.',
        noDescription:'Brak opisu.',
        copyText:'Skopiowano zawartość do schowka.',
        share:'Udostępnij',
        deleteChargingPoint:'Usuń Punkt ładowania',
        editChargingPoint:'Edytuj Punkt ładowania',
        goToWebsite:'Przekieruj do strony',
    }

}