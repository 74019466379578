import App from './App.vue';
import './assets/tailwind.css';
import 'node-snackbar/dist/snackbar.css';
import router from './router';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { createApp, h, provide } from 'vue';
import VueTagsInput from '@james090500/vue-tagsinput';
import '@james090500/vue-tagsinput/dist/style.css';
import { translations } from './translations.js'; 

const initApp = async () => {
  const { default: apolloClient } = await import('@/services/apollo');

  const app = createApp({
    setup() {
      provide(DefaultApolloClient, apolloClient);
      provide('translations', translations[language]);
    },

    render: () => h(App),
  });

  app.component('tags-input', VueTagsInput);

  app.use(router);

  const userLanguage = navigator.language || navigator.userLanguage;
  const language = userLanguage.startsWith('pl') ? 'pl' : 'en';
  console.log(language);



  app.mount('#app');
};

(async function () {
  const requestEnv = await fetch(`${document.location.origin}/.env`).then((r) =>
    r.json()
  );
  sessionStorage.setItem('api_url', requestEnv.api_url);

  await initApp();
})();