import { createRouter } from 'vue-router'
import { createWebHistory } from 'vue-router'
import { getAuthToken } from '@/services/session'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
        path: '/',
        redirect: '/login', 
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('../views/LoginView.vue'),
      beforeEnter: () => {
        if(getAuthToken()) {
          return { name: 'Map' }
        }
      }
    },
    {
      path: '/map',
      name: 'Map',
      component: () => import('../views/MapView.vue'),
      beforeEnter: () => {
        if (!getAuthToken()) {
          return { name: 'Login' }
        } 
      }
    },
    {
      path: '/list',
      name: 'List',
      component: () => import('../views/ListView.vue'),
      beforeEnter: () => {
        if (!getAuthToken()) {
          return { name: 'Login' }
        } 
      }
    },
    {
      path: '/chargingpoint/edit/:id?',
      name: 'ChargingPointEdit',
      component: () => import('../views/EditChargingPointView.vue'),
      beforeEnter: () => {
        if (!getAuthToken()) {
          return { name: 'Login' }
        } 
      }
    },
    {
        path: '/feedback',
        name: 'Feedback',
        component: () => import('../views/FeedbackView.vue'),
        beforeEnter: () => {
          if (!getAuthToken()) {
            return { name: 'Login' }
          } 
        }
      },
  ]
})


export default router
